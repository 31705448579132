// import { ChainId } from '@pancakeswap-libs/sdk';
import { ChainId } from '@spookyswap/sdk';
import { Configuration } from './based-finance/config';
import { BankInfo } from './based-finance';

const configurations: { [env: string]: Configuration } = {
  development: {
    chainId: ChainId.FTMTESTNET,
    networkName: 'Fantom Opera Testnet',
    ftmscanUrl: 'https://testnet.ftmscan.com',
    defaultProvider: 'https://rpc.testnet.fantom.network/',
    deployments: require('./based-finance/deployments/deployments.testing.json'),
    externalTokens: {
      TOMB: ['0xDdE62CAc89D515F4F938D9d3B9962508ba56734c', 18],
      WFTM: ['0xf1277d1ed8ad466beddf92ef448a132661956621', 18],
      FUSDT: ['0xb7f24e6e708eabfaa9e64b40ee21a5adbffb51d6', 6],
      BOO: ['0x14f0C98e6763a5E13be5CE014d36c2b69cD94a1e', 18],
      ZOO: ['0x2317610e609674e53D9039aaB85D8cAd8485A7c5', 0],
      SHIBA: ['0x39523112753956d19A3d6a30E758bd9FF7a8F3C0', 9],
      'USDC-FTM-LP': ['0x7a6C9B27e20560253d4080944A252494C702f1a2', 18],
      'BSHARE-FTM-LP': ['0x52A5B9E36F53b54Ed9ddd7a4e66Ac26696E9F0be', 18],
      'BASED-TOMB-LP': ['0xf799AEa5df9fc8faC93D5e2A5277b4E82817ccB5', 18],
    },
    baseLaunchDate: new Date('2021-06-02 13:00:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    acropolisLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 10000,
  },
  production: {
    chainId: ChainId.MAINNET,
    networkName: 'Fantom Opera Mainnet',
    ftmscanUrl: 'https://ftmscan.com',
    defaultProvider: 'https://rpc.ankr.com/fantom',
    deployments: require('./based-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      TOMB: ['0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7', 18],
      BBOND: ['0xC078285F16665B3F4bCe74AbDCF0f4C877de3E9f', 18],
     
      WFTM: ['0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', 18],
      USDC: ['0x04068DA6C83AFCFA0e13ba15A6696662335D5B75', 6],
      MAI: ['0xfB98B335551a418cD0737375a2ea0ded62Ea213b', 18],
      CURVE_GEIST: ['0xd4f94d0aaa640bbb72b5eec2d85f6d114d81a88e', 18],
      CURVE_TRICRYPTO: ['0x00702bbdead24c40647f235f15971db0867f6bdb', 18],
      CURVE_GEIST_V2: ['0xD02a30d33153877BC20e5721ee53DeDEE0422B2F', 18],
      CURVE_TRICRYPTO_V2: ['0x58e57cA18B7A47112b877E31929798Cd3D703b0f', 18],
      'FTM-USDC-LP': ['0x7a6C9B27e20560253d4080944A252494C702f1a2', 18],
      'BSHARE-FTM-LP': ['0x52A5B9E36F53b54Ed9ddd7a4e66Ac26696E9F0be', 18],
      'BASED-FTM-LP': ['0x0981916Bd96d375dAd0067Fd24E19120D2fcF5e6', 18],
      'BASED-TOMB-LP': ['0xf799AEa5df9fc8faC93D5e2A5277b4E82817ccB5', 18],
      'FTM-TOMB-LP': ['0xd4DDdF08F12e8eA1d7dd5a47418cDF3d93A5be96', 18],
      'BASED-MAI-LP': ['0x7B5B3751550be4FF87aC6bda89533F7A0c9825B3', 18],
      'BASED-USDC-LP': ['0x7c849a7E2cb08f09cf37482cc0A04ecB5891844a', 18],
      'BASED-TOMB-TSWAP-LP': ['0xf799AEa5df9fc8faC93D5e2A5277b4E82817ccB5', 18],
      'MOO-BASED-BASED-TOMB': ['0xEb27F60f652fF53cBf3EfF0c7033B380148b6CB9', 18],
    },
    baseLaunchDate: new Date('2022-02-05 13:00:00Z'),
    bondLaunchesAt: new Date('2022-02-07T15:00:00Z'),
    acropolisLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 10000,
  },
};

export const bankDefinitions: { [contractName: string]: BankInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding TOMB
        - 2 = LP asset staking rewarding TSHARE
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */
 

 BasedTombLPDeadPool: {
   name: 'BASED-TOMB Dead Pool',
   poolId: 0,
   sectionInUI: 0,
   contract: 'BasedTombLPDeadPool',
   depositTokenName: 'BASED-TOMB-LP',
   earnTokenName: 'MAI',
   finished: false,
   sort: 1,
   exchange: 'basedSwap',
   closedForStaking: true,
  },
  
  BbondDeadPool: {
      name: 'BBOND Dead Pool',
      poolId: 2,
      sectionInUI: 0,
      contract: 'BbondDeadPool',
      depositTokenName: 'BBOND',
      earnTokenName: 'MAI',
      finished: false,
      sort: 3,
      exchange: 'basedSwap',
      closedForStaking: true,
    },
  BasedTombLPDeadPoolUSDC: {
      name: 'BASED-TOMB Dead Pool',
      poolId: 0,
      sectionInUI: 0,
      contract: 'BasedTombLPDeadPoolUSDC',
      depositTokenName: 'BASED-TOMB-LP',
      earnTokenName: 'USDC',
      finished: false,
      sort: 1,
      exchange: 'basedSwap',
      closedForStaking: true,
     },
  BasedFtmLPDeadPoolUSDC: {
      name: 'BASED-FTM Dead Pool',
      poolId: 3,
      sectionInUI: 2,
      contract: 'BasedFtmLPDeadPoolUSDC',
      depositTokenName: 'BASED-FTM-LP',
      earnTokenName: 'USDC',
      finished: false,
      sort: 1,
      exchange: 'basedSwap',
      closedForStaking: false,
     },
   
   BshareFtmLPDeadPoolUSDC: {
     name: 'BSHARE-FTM Dead Pool',
     poolId: 1,
     sectionInUI: 2,
     contract: 'BshareFtmLPDeadPoolUSDC',
     depositTokenName: 'BSHARE-FTM-LP',
     earnTokenName: 'USDC',
     finished: false,
     sort: 2,
     exchange: 'basedSwap',
     closedForStaking: false,
   },
  
   BbondDeadPoolUSDC: {
     name: 'BBOND Dead Pool',
     poolId: 2,
     sectionInUI: 2,
     contract: 'BbondDeadPoolUSDC',
     depositTokenName: 'BBOND',
     earnTokenName: 'USDC',
     finished: false,
     sort: 3,
     exchange: 'basedSwap',
     closedForStaking: false,
   },
};

export default configurations['production'];
