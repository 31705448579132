import { Fetcher, Fetcher as FetcherSpirit, Route, Token, Token as TokenSpirit } from '@spookyswap/sdk';
import { Configuration } from './config';
import {
  AllocationTime,
  Bank,
  BShareSwapperStat,
  ContractName,
  FTMLPStat,
  MAILPStat,
  PoolStats,
  ShareTokenStat,
  TokenStat,
  TOMBLPStat,
  USDCLPStat,
} from './types';
import { BigNumber, Contract, ethers, Event, EventFilter } from 'ethers';
import { decimalToBalance } from './ether-utils';
import { TransactionResponse } from '@ethersproject/providers';
import ERC20 from './ERC20';
import { getDisplayBalance, getFullDisplayBalance } from '../utils/formatBalance';
import { getDefaultProvider } from '../utils/provider';
import IUniswapV2PairABI from './IUniswapV2Pair.abi.json';
import config, { bankDefinitions } from '../config';
import moment from 'moment';
import { parseUnits } from 'ethers/lib/utils';
import {
  BASED_TICKER,
  BSHARE_TICKER,
  BBOND_TICKER,
  BURN_ACCELERATOR_ADDR,
  FTM_TICKER,
  MAI_TICKER,
  SPOOKY_ROUTER_ADDR,
  TICKER,
  TOMB_TICKER,
  TOMBSWAP_ROUTER_ADDR,
  WFTM_TICKER,
} from '../utils/constants';
import {Pair,Route as Sroute,Trade} from './uniswap-based-sdk'
import {CurrencyAmount,Token as Stoken,Price} from '@uniswap/sdk-core';
import { getCreate2Address } from '@ethersproject/address'
import { pack, keccak256 } from '@ethersproject/solidity'
//import useStakedTokenPriceInDollars from '../hooks/useStakedTokenPriceInDollars';
/**
 * An API module of Based Finance contracts.
 * All contract-interacting domain logic should be defined in here.
 */
export class BasedFinance {
  myAccount: string;
  provider: ethers.providers.Web3Provider;
  signer?: ethers.Signer;
  config: Configuration;
  contracts: { [name: string]: Contract };
  externalTokens: { [name: string]: ERC20 };
  acropolisVersionOfUser?: string;
  parthenonVersionOfUser?: string;
  acropolisFundEvents: Array<Event> = [];
  lastEpoch: number = 0;

  BASEDTOMB_LP: Contract;
  BSHAREFTM_LP: Contract;
  BASEDTOMBTSWAP_LP: Contract;
  BASEDMAI_LP: Contract;
  BASEDUSDC_LP: Contract;

  BASED: ERC20;
  BSHARE: ERC20;
  BBOND: ERC20;
  TOMB: ERC20;
  TSHARE: ERC20;
  SOLID: ERC20;
  FTM: ERC20;
  WFTM: ERC20;
  USDC: ERC20;
  MAI: ERC20;
  BTLP: ERC20; // BASED-TOMB-LP
  BFLP: ERC20; //BSHARE-FTM-LP
  BTTSLP: ERC20; //BASED-TOMB-TOMBSWAP-LP
  BMLP: ERC20; // BASED-MAI-LP
  BULP: ERC20; // BASED-USDC-LP
  MBBT: ERC20; // MOO-BASED-BASED-TOMB receipt token
  CURVE_GEIST: ERC20;
  CURVE_TRICRYPTO: ERC20;
  CURVE_GEIST_V2: ERC20;
  CURVE_TRICRYPTO_V2: ERC20;

  constructor(cfg: Configuration) {
    const { deployments, externalTokens } = cfg;
    const provider = getDefaultProvider();

    // loads contracts from deployments
    this.contracts = {};
    for (const [name, deployment] of Object.entries(deployments)) {
      this.contracts[name] = new Contract(deployment.address, deployment.abi, provider);
    }
    this.externalTokens = {};
    for (const [symbol, [address, decimal]] of Object.entries(externalTokens)) {
      this.externalTokens[symbol] = new ERC20(address, provider, symbol, decimal);
    }
    this.BASED = new ERC20(deployments.based.address, provider, 'BASED');
    this.BSHARE = new ERC20(deployments.bShare.address, provider, 'BSHARE');
    this.BBOND = new ERC20(deployments.bBond.address, provider, 'BBOND');
    this.TOMB = this.externalTokens['TOMB'];
    this.TSHARE = this.externalTokens['TSHARE'];
    this.SOLID = this.externalTokens['SOLID'];
    this.USDC = this.externalTokens['USDC'];
    this.MAI = this.externalTokens['MAI'];
    this.FTM = this.externalTokens['WFTM'];
    this.WFTM = this.externalTokens['WFTM'];
    this.BTLP = this.externalTokens['BASED-TOMB-LP'];
    this.BFLP = this.externalTokens['BSHARE-FTM-LP'];
    this.BTTSLP = this.externalTokens['BASED-TOMB-TSWAP-LP'];
    this.BMLP = this.externalTokens['BASED-MAI-LP'];
    this.BULP = this.externalTokens['BASED-USDC-LP'];
    this.MBBT = this.externalTokens['MOO-BASED-BASED-TOMB'];

    // Uniswap V2 Pair

    this.BASEDTOMB_LP = new Contract(externalTokens['BASED-TOMB-LP'][0], IUniswapV2PairABI, provider);
    this.BASEDMAI_LP = new Contract(externalTokens['BASED-MAI-LP'][0], IUniswapV2PairABI, provider);
    this.BSHAREFTM_LP = new Contract(externalTokens['BSHARE-FTM-LP'][0], IUniswapV2PairABI, provider);
    this.BASEDTOMBTSWAP_LP = new Contract(externalTokens['BASED-TOMB-TSWAP-LP'][0], IUniswapV2PairABI, provider);
    this.BASEDUSDC_LP = new Contract(externalTokens['BASED-USDC-LP'][0], IUniswapV2PairABI, provider);

    this.config = cfg;
    this.provider = provider;
  }

  /**
   * @param provider From an unlocked wallet. (e.g. Metamask)
   * @param account An address of unlocked wallet account.
   */
  unlockWallet(provider: any, account: string) {
    const newProvider = new ethers.providers.Web3Provider(provider, this.config.chainId);
    this.signer = newProvider.getSigner(0);
    this.myAccount = account;
    for (const [name, contract] of Object.entries(this.contracts)) {
      this.contracts[name] = contract.connect(this.signer);
    }
    const tokens = [this.BASED, this.BSHARE, this.BBOND, ...Object.values(this.externalTokens)];
    for (const token of tokens) {
      token.connect(this.signer);
    }
    this.BASEDTOMB_LP = this.BASEDTOMB_LP.connect(this.signer);
    this.BSHAREFTM_LP = this.BSHAREFTM_LP.connect(this.signer);

    console.log(`🔓 Wallet is unlocked. Welcome, ${account}!`);
    this.fetchAcropolisVersionOfUser()
      .then((version) => (this.acropolisVersionOfUser = version))
      .catch((err) => {
        console.error(`Failed to fetch acropolis version: ${err.stack}`);
        this.acropolisVersionOfUser = 'latest';
      });
  }

  get isUnlocked(): boolean {
    return !!this.myAccount;
  }

  //===================================================================
  //===================== GET ASSET STATS =============================
  //===================FROM SPOOKY TO DISPLAY =========================
  //=========================IN HOME PAGE==============================
  //===================================================================

  async getBasedStat(): Promise<TokenStat> {
    const { BasedTombGenesisRewardPool, BasedTombLPGenesisRewardPool } = this.contracts;
    const supply = await this.BASED.totalSupply();
    const basedRewardPoolSupply = await this.BASED.balanceOf(BasedTombGenesisRewardPool.address);
    //const basedRewardPoolSupply2 = await this.BASED.balanceOf(BasedTombLPGenesisRewardPool.address);
    //const basedRewardPoolSupplyOld = await this.BASED.balanceOf(BasedTombLpBasedRewardPoolOld.address);
    const basedCirculatingSupply = supply.sub(basedRewardPoolSupply);
    //.sub(basedRewardPoolSupply2)
    const priceInTOMB = await this.getTokenPriceInFtmShort(this.BASED);
    // const priceOfOneTOMB = await this.getOneTOMBPriceInFTM();
    const priceOfOneFTM = await this.getFTMPriceInDollars();
    const priceOfBasedInDollars = (Number(priceInTOMB) * Number(priceOfOneFTM)).toFixed(5);

    return {
      tokenInTomb: priceInTOMB,
      priceInDollars: priceOfBasedInDollars,
      totalSupply: getDisplayBalance(supply, this.BASED.decimal, 0),
      circulatingSupply: getDisplayBalance(basedCirculatingSupply, this.BASED.decimal, 0),
    };
  }

  /**
   * Calculates various stats for the requested LP
   * @param name of the LP token to load stats for
   * @returns
   */
  async getTombLPStat(name: string): Promise<TOMBLPStat> {
    const lpToken = this.externalTokens[name];
    const lpTokenSupplyBN = await lpToken.totalSupply();
    const lpTokenSupply = getDisplayBalance(lpTokenSupplyBN, 18);
    const token0 = this.BASED;
    const isBased = name.startsWith('BASED');
    const tokenAmountBN = await token0.balanceOf(lpToken.address);
    const tokenAmount = getDisplayBalance(tokenAmountBN, 18);

    const tombAmountBN = await this.TOMB.balanceOf(lpToken.address);
    const tombAmount = getDisplayBalance(tombAmountBN, 18);

    const tokenAmountInOneLP = Number(tokenAmount) / Number(lpTokenSupply);
    const tombAmountInOneLP = Number(tombAmount) / Number(lpTokenSupply);
    const lpTokenPrice = await this.getLPTokenPrice(lpToken, token0, isBased);

    const lpTokenPriceFixed = Number(lpTokenPrice).toFixed(8).toString();
    const liquidity = (Number(lpTokenSupply) * Number(lpTokenPrice)).toFixed(8).toString();
    return {
      tokenAmount: tokenAmountInOneLP.toFixed(8).toString(),
      tombAmount: tombAmountInOneLP.toFixed(8).toString(),
      priceOfOne: lpTokenPriceFixed,
      totalLiquidity: liquidity,
      totalSupply: Number(lpTokenSupply).toFixed(8).toString(),
    };
  }

  async getMaiLPStat(name: string): Promise<MAILPStat> {
    const lpToken = this.externalTokens[name];
    const lpTokenSupplyBN = await lpToken.totalSupply();
    const lpTokenSupply = getDisplayBalance(lpTokenSupplyBN, 18);
    const token0 = this.BASED;
    const isBased = name.startsWith('BASED');
    const tokenAmountBN = await token0.balanceOf(lpToken.address);
    const tokenAmount = getDisplayBalance(tokenAmountBN, 18);

    const maiAmountBN = await this.MAI.balanceOf(lpToken.address);
    const maiAmount = getDisplayBalance(maiAmountBN, 18);

    const tokenAmountInOneLP = Number(tokenAmount) / Number(lpTokenSupply);
    const maiAmountInOneLP = Number(maiAmount) / Number(lpTokenSupply);
    const lpTokenPrice = await this.getLPTokenPrice(lpToken, token0, isBased);

    const lpTokenPriceFixed = Number(lpTokenPrice).toFixed(8).toString();
    const liquidity = (Number(lpTokenSupply) * Number(lpTokenPrice)).toFixed(8).toString();
    return {
      basedAmount: tokenAmountInOneLP.toFixed(8).toString(),
      maiAmount: maiAmountInOneLP.toFixed(8).toString(),
      priceOfOne: lpTokenPriceFixed,
      totalLiquidity: liquidity,
      totalSupply: Number(lpTokenSupply).toFixed(8).toString(),
    };
  }

  async getUSDCLPStat(name: string): Promise<USDCLPStat> {
    const lpToken = this.externalTokens[name];
    const lpTokenSupplyBN = await lpToken.totalSupply();
    const lpTokenSupply = getDisplayBalance(lpTokenSupplyBN, 18);
    const token0 = this.BASED;
    const isBased = name.startsWith('BASED');
    const tokenAmountBN = await token0.balanceOf(lpToken.address);
    const tokenAmount = getDisplayBalance(tokenAmountBN, 18);

    const USDCAmountBN = await this.USDC.balanceOf(lpToken.address);
    const USDCAmount = getDisplayBalance(USDCAmountBN, 18);

    const tokenAmountInOneLP = Number(tokenAmount) / Number(lpTokenSupply);
    const USDCAmountInOneLP = Number(USDCAmount) / Number(lpTokenSupply);
    const lpTokenPrice = await this.getLPTokenPrice(lpToken, token0, isBased);

    const lpTokenPriceFixed = Number(lpTokenPrice).toFixed(8).toString();
    const liquidity = (Number(lpTokenSupply) * Number(lpTokenPrice)).toFixed(8).toString();
    return {
      basedAmount: tokenAmountInOneLP.toFixed(8).toString(),
      USDCAmount: USDCAmountInOneLP.toFixed(8).toString(),
      priceOfOne: lpTokenPriceFixed,
      totalLiquidity: liquidity,
      totalSupply: Number(lpTokenSupply).toFixed(8).toString(),
    };
  }

  async getFtmLPStat(name: string): Promise<FTMLPStat> {
    const lpToken = this.externalTokens[name];
    const lpTokenSupplyBN = await lpToken.totalSupply();
    const lpTokenSupply = getDisplayBalance(lpTokenSupplyBN, 18);

    const token0 = this.BSHARE;
    const isBased = name.startsWith('BASED');
    const tokenAmountBN = await token0.balanceOf(lpToken.address);
    const tokenAmount = getDisplayBalance(tokenAmountBN, 18);

    const ftmAmountBN = await this.WFTM.balanceOf(lpToken.address);
    const ftmAmount = getDisplayBalance(ftmAmountBN, 18);
    const tokenAmountInOneLP = Number(tokenAmount) / Number(lpTokenSupply);
    const ftmAmountInOneLP = Number(ftmAmount) / Number(lpTokenSupply);
    const lpTokenPrice = await this.getLPTokenPrice(lpToken, token0, isBased);
    const lpTokenPriceFixed = Number(lpTokenPrice).toFixed(8).toString();
    const liquidity = (Number(lpTokenSupply) * Number(lpTokenPrice)).toFixed(8).toString();
    return {
      tokenAmount: tokenAmountInOneLP.toFixed(8).toString(),
      ftmAmount: ftmAmountInOneLP.toFixed(8).toString(),
      priceOfOne: lpTokenPriceFixed,
      totalLiquidity: liquidity,
      totalSupply: Number(lpTokenSupply).toFixed(8).toString(),
    };
  }
  async getBasedFtmLPStat(name: string): Promise<FTMLPStat> {
    const lpToken = this.externalTokens[name];
    const lpTokenSupplyBN = await lpToken.totalSupply();
    const lpTokenSupply = getDisplayBalance(lpTokenSupplyBN, 18);

    const token0 = this.BASED;
    const isBased = name.startsWith('BASED');
    const tokenAmountBN = await token0.balanceOf(lpToken.address);
    const tokenAmount = getDisplayBalance(tokenAmountBN, 18);

    const ftmAmountBN = await this.WFTM.balanceOf(lpToken.address);
    const ftmAmount = getDisplayBalance(ftmAmountBN, 18);
    const tokenAmountInOneLP = Number(tokenAmount) / Number(lpTokenSupply);
    const ftmAmountInOneLP = Number(ftmAmount) / Number(lpTokenSupply);
    const lpTokenPrice = await this.getLPTokenPrice(lpToken, token0, isBased);
    const lpTokenPriceFixed = Number(lpTokenPrice).toFixed(8).toString();
    const liquidity = (Number(lpTokenSupply) * Number(lpTokenPrice)).toFixed(8).toString();
    return {
      tokenAmount: tokenAmountInOneLP.toFixed(8).toString(),
      ftmAmount: ftmAmountInOneLP.toFixed(8).toString(),
      priceOfOne: lpTokenPriceFixed,
      totalLiquidity: liquidity,
      totalSupply: Number(lpTokenSupply).toFixed(8).toString(),
    };
  }

  /**
   * Use this method to get price for Based
   * @returns TokenStat for BBOND
   * priceInTOMB
   * priceInDollars
   * TotalSupply
   * CirculatingSupply (always equal to total supply for bonds)
   */
  async getBondStat(): Promise<TokenStat> {
    const { Treasury } = this.contracts;
    const basedStat = await this.getBasedStat();
    const bondBasedRatioBN = await Treasury.getBondPremiumRate();
    const modifier = bondBasedRatioBN / 1e18 > 1 ? bondBasedRatioBN / 1e18 : 1;
    const bondPriceInTOMB = (Number(basedStat.tokenInTomb) * modifier).toFixed(4);
    const priceOfBBondInDollars = (Number(basedStat.priceInDollars) * modifier).toFixed(5);
    const supply = await this.BBOND.displayedTotalSupply();
    return {
      tokenInTomb: bondPriceInTOMB,
      priceInDollars: priceOfBBondInDollars,
      totalSupply: supply,
      circulatingSupply: supply,
    };
  }

  /**
   * @returns TokenStat for BSHARE
   * priceInFTM
   * priceInDollars
   * TotalSupply
   * CirculatingSupply (always equal to total supply for bonds)
   * ===================BSHARE PRICE IN WFTM ONLY!!!================
   */
  async getShareStat(): Promise<ShareTokenStat> {
    const { BshareFtmLPDeadPool } = this.contracts;

    const supply = await this.BSHARE.totalSupply();

    const priceInFTM = await this.getTokenPriceInFtmShort(this.BSHARE);
    const bshareRewardPoolSupply = await this.BSHARE.balanceOf(BshareFtmLPDeadPool.address);
    const bShareCirculatingSupply = supply.sub(bshareRewardPoolSupply);
    const priceOfOneFTM = await this.getFTMPriceInDollars();
    const priceOfSharesInDollars = (Number(priceInFTM) * Number(priceOfOneFTM)).toFixed(5);

    return {
      tokenInFtm: priceInFTM,
      priceInDollars: priceOfSharesInDollars,
      totalSupply: getDisplayBalance(supply, this.BSHARE.decimal, 0),
      circulatingSupply: getDisplayBalance(bShareCirculatingSupply, this.BSHARE.decimal, 0),
    };
  }

  async getBasedStatInEstimatedTWAP(): Promise<TokenStat> {
    const { SeigniorageOracle, BasedTombGenesisRewardPool } = this.contracts;
    const expectedPrice = await SeigniorageOracle.twap(this.BASED.address, ethers.utils.parseEther('1'));

    const supply = await this.BASED.totalSupply();
    const basedRewardPoolSupply = await this.BASED.balanceOf(BasedTombGenesisRewardPool.address);
    const basedCirculatingSupply = supply.sub(basedRewardPoolSupply);
    return {
      tokenInTomb: getDisplayBalance(expectedPrice),
      priceInDollars: getDisplayBalance(expectedPrice),
      totalSupply: getDisplayBalance(supply, this.BASED.decimal, 0),
      circulatingSupply: getDisplayBalance(basedCirculatingSupply, this.BASED.decimal, 0),
    };
  }

  async getBasedPriceInLastTWAP(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.getBasedUpdatedPrice();
  }

  async getCurrentBasedPrice(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return await Treasury.getBasedPrice();
  }

  async getBondsPurchasable(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.getBurnableBasedLeft();
  }

  /**
   * Calculates the TVL, APR and daily APR of a provided pool/bank
   * @param bank
   * @returns
   */
  async getPoolAPRs(bank: Bank): Promise<PoolStats> {
    if (this.myAccount === undefined) return;
    const depositToken = bank.depositToken;
    const poolContract = this.contracts[bank.contract];
    const depositTokenPrice = await this.getDepositTokenPriceInDollars(bank.depositTokenName, depositToken);
    const stakeInPool = await depositToken.balanceOf(bank.address);
    const TVL = Number(depositTokenPrice) * Number(getDisplayBalance(stakeInPool, depositToken.decimal));
    const stat = bank.earnTokenName === 'BASED' ? await this.getBasedStat() : await this.getShareStat();
    const tokenPerSecond = await this.getTokenPerSecond(
      bank.earnTokenName,
      bank.contract,
      poolContract,
      bank.depositTokenName,
      bank.poolId
    );

    const tokenPerHour = tokenPerSecond.mul(60).mul(60);

    let totalRewardPricePerYear =  Number(1) * Number(getDisplayBalance(tokenPerHour.mul(24).mul(365)));
    let totalRewardPricePerDay = Number(1) * Number(getDisplayBalance(tokenPerHour.mul(24)));

    if( bank.earnTokenName != 'USDC' && bank.earnTokenName != 'MAI' ){
      totalRewardPricePerYear =  Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(24).mul(365)));
      totalRewardPricePerDay = Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(24)));
    }

    const totalStakingTokenInPool =
      Number(depositTokenPrice) * Number(getDisplayBalance(stakeInPool, depositToken.decimal));
    const dailyAPR = (totalRewardPricePerDay / totalStakingTokenInPool) * 100;
    const yearlyAPR = (totalRewardPricePerYear / totalStakingTokenInPool) * 100;


    return {
      dailyAPR: dailyAPR.toFixed(2).toString(),
      yearlyAPR: yearlyAPR.toFixed(2).toString(),
      TVL: TVL.toFixed(2).toString(),
    };
  }

  /**
   * Gets BShare pool allocation with pool name
   *
   * 
   * @param {number} poolName The pool name to get allocation points for
   * @return {Promise<Number>} The pool allocation points
   */
  async getBSharePoolsAllocation(poolName: string): Promise<Number> {
    const { BbondDeadPool } = this.contracts;
    console.log(poolName)
    const poolId: number = this.getPoolIdFromPoolName(poolName);
    let poolAllocation: any;

    try {
      poolAllocation = await BbondDeadPool.poolInfo(poolId);
    } catch (error) {
      console.error(error);
    }

    if (poolAllocation) {
      console.log("Pool " + poolId + " Allic " + Number(getDisplayBalance(poolAllocation.allocPoint, 18, 0)))

      return Number(getDisplayBalance(poolAllocation.allocPoint, 18, 0));
    } else {
      return 0;
    }
  }

  async getPoolsAllocation(poolName: string, poolId: number, poolContract: Contract): Promise<Number> {
    let poolAllocation: any;

    try {
      poolAllocation = await poolContract.poolInfo(poolId);
    } catch (error) {
      console.error(error);
    }

    if (poolAllocation) {
      return Number(getDisplayBalance(poolAllocation.allocPoint, 18, 0));
    } else {
      return 0;
    }
  }

  /**
   * Method to return the amount of tokens the pool yields per second
   * @param earnTokenName the name of the token that the pool is earning
   * @param contractName the contract of the pool/bank
   * @param poolContract the actual contract of the pool
   * @param depositTokenName the name of the deposit token
   * @returns
   */
  async getTokenPerSecond(
    earnTokenName: string,
    contractName: string,
    poolContract: Contract,
    depositTokenName: string,
    poolId: number
  ) {
      const rewardPerSecond = await poolContract.rewardPerSecond();
      const totalAllocPoints = rewardPerSecond.mul(60 * 60 * 24 * 365);
      return rewardPerSecond.mul(await this.getPoolsAllocation(depositTokenName, poolId, poolContract)).div(getDisplayBalance(totalAllocPoints, 18, 0));
  }

  /**
   * Method to calculate the tokenPrice of the deposited asset in a pool/bank
   * If the deposited token is an LP it will find the price of its pieces
   * @param tokenName
   * @param token
   * @returns
   */
  async getDepositTokenPriceInDollars(tokenName: string, token: ERC20) {
    let tokenPrice;
    let tokenPriceInFtm;
    const priceOfOneFtmInDollars = await this.getFTMPriceInDollars();

    if (tokenName === 'WFTM') {
      tokenPrice = priceOfOneFtmInDollars;
    } else if (tokenName === 'TOMB') {
      tokenPriceInFtm = await this.getTokenPriceInFtmShort(token);
      tokenPrice = (Number(tokenPriceInFtm) * Number(priceOfOneFtmInDollars)).toString();
    } else if (tokenName === 'ETH') {
      tokenPriceInFtm = await this.getTokenPriceInFtmShort(token);
      tokenPrice = (Number(tokenPriceInFtm) * Number(priceOfOneFtmInDollars)).toString();
    } else if (tokenName === 'BASED-TOMB-LP') {
      tokenPrice = await this.getLPTokenPrice(token, this.BASED, true);
    } else if (tokenName === 'BSHARE-FTM-LP') {
      tokenPrice = await this.getLPTokenPrice(token, this.BSHARE, false);
    } else if (tokenName === 'STATER') {
      tokenPrice = '0.00001';
    } else if (tokenName === 'CURVE_GEIST' || tokenName === 'CURVE_GEIST_V2') {
      tokenPrice = '1.01';
    } else if (tokenName === 'CURVE_TRICRYPTO' || tokenName === 'CURVE_TRICRYPTO_V2') {
      tokenPrice = await this.getTricryptoPriceInDollars();
    } else if (tokenName === 'BASED-BSHARE-LP') {
      tokenPrice = await this.getLPTokenPrice(token, this.BSHARE, false);
    } else if (tokenName === 'BASED-FTM-LP') {
      tokenPrice = await this.getLPTokenPrice(token, this.BASED, true);
    } else if (tokenName === 'BASED-TOMB-TSWAP-LP') {
      tokenPrice = await this.getLPTokenPrice(token, this.BASED, true);
    } else if (tokenName === 'BASED-USDC-LP') {
      tokenPrice = await this.getLPTokenPrice(token, this.BASED, true);
    } else if (tokenName === 'BBOND') {
      let bbondData = await this.getBondStat();
      tokenPrice = Number(bbondData?.priceInDollars).toFixed(8);
    } else {
      // =====================usdc deposit
      tokenPriceInFtm = await this.getTokenPriceInFtmShort(token);
      tokenPrice = (Number(tokenPriceInFtm) * Number(priceOfOneFtmInDollars)).toString();
    }
    return tokenPrice;
  }

  //===================================================================
  //===================== GET ASSET STATS =============================
  //=========================== END ===================================
  //===================================================================

  async getCurrentEpoch(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.epoch();
  }

  async getBondOraclePriceInLastTWAP(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.getBondPremiumRate();
  }

  /**
   * Buy bonds with cash.
   * @param amount amount of cash to purchase bonds with.
   */
  async buyBonds(amount: string | number): Promise<TransactionResponse> {
    const { Treasury } = this.contracts;
    const basedPrice = await Treasury.getBasedPrice();
    return await Treasury.buyBonds(decimalToBalance(amount), basedPrice);
  }

  /**
   * Redeem bonds for cash.
   * @param amount amount of bonds to redeem.
   */
  async redeemBonds(amount: string): Promise<TransactionResponse> {
    const { Treasury } = this.contracts;
    const basedPrice = await Treasury.getBasedPrice();
    return await Treasury.redeemBonds(decimalToBalance(amount), basedPrice);
  }

  //---------------- ToDo Replace this with real contract call ----------------
  mockTransactionResponse = {
    hash: '0x0',
    confirmations: 1,
    from: '0x0',
    wait: () => {},
  };

  /**
   * Buy bonds with USDC/WFTM/MAI.
   * @param amount amount of tokens to purchase bonds with.
   * @param ticker ticker for the token to purchase bonds with.
   */
  async instaSwapBond(amount: string | number, ticker: string): Promise<TransactionResponse> {
    return new Promise((resolve) => {
      this.mockTransactionResponse.hash = Math.random().toString();
      resolve(this.mockTransactionResponse as TransactionResponse);
    });
  }

  async instaSwapBondNative(amount: string | number): Promise<TransactionResponse> {
    return new Promise((resolve) => {
      this.mockTransactionResponse.hash = Math.random().toString();
      resolve(this.mockTransactionResponse as TransactionResponse);
    });
  }

  async getBasedTresuryBalance(): Promise<BigNumber> {
    return new Promise((resolve) => {
      resolve(BigNumber.from('1000000000000000000000'));
    });
  }

  //---------------- ToDo Replace this with real contract call ----------------

  async getBasedPriceInToken(tokenContract: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { chainId } = this.config;

    const based = new Token(chainId, this.BASED.address, this.BASED.decimal);
    const token = new Token(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
    try {
      const basedToToken = await Fetcher.fetchPairData(token, based, this.provider);
      const priceInBUSD = new Route([basedToToken], based);

      return priceInBUSD.midPrice.toFixed(4);
    } catch (err) {
      console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
    }
  }

  async getTotalValueLocked(): Promise<Number> {
    let totalValue = 0;
    for (const bankInfo of Object.values(bankDefinitions)) {
      const pool = this.contracts[bankInfo.contract];
      const token = this.externalTokens[bankInfo.depositTokenName];
      const tokenPrice = await this.getDepositTokenPriceInDollars(bankInfo.depositTokenName, token);
      const tokenAmountInPool = await token.balanceOf(pool.address);
      const value = Number(getDisplayBalance(tokenAmountInPool, token.decimal)) * Number(tokenPrice);
      const poolValue = Number.isNaN(value) ? 0 : value;
      totalValue += poolValue;
    }

    const BSHAREPrice = (await this.getShareStat()).priceInDollars;
    const acropolisbShareBalanceOf = await this.BSHARE.balanceOf(this.currentAcropolis().address);
    const acropolisTVL = Number(getDisplayBalance(acropolisbShareBalanceOf, this.BSHARE.decimal)) * Number(BSHAREPrice);

    return totalValue + acropolisTVL;
  }
  async getBurntAmount(asset: string): Promise<Number> {
    // const { AcceleratorWallet } = BURN_ACCELERATOR_ADDR;
    
    let burntToken: ERC20;
    switch (asset) {
      case BASED_TICKER:
        burntToken = this.BASED;
        break;
      case BSHARE_TICKER:
        burntToken = this.BSHARE;
        break;
      case BBOND_TICKER:
        burntToken = this.BBOND;
        break;
      
      default:
        burntToken = this.BASED;
    }
   
    const burntAmount = Number(getFullDisplayBalance(await burntToken.balanceOf(BURN_ACCELERATOR_ADDR), burntToken.decimal));
    return burntAmount;
  }

  /**
   * Calculates the price of an LP token
   * Reference https://github.com/DefiDebauchery/discordpricebot/blob/4da3cdb57016df108ad2d0bb0c91cd8dd5f9d834/pricebot/pricebot.py#L150
   * @param lpToken the token under calculation
   * @param token the token pair used as reference (the other one would be TOMB in most cases)
   * @param isBased sanity check for usage of based token or bShare
   * @returns price of the LP token
   */
  async getLPTokenPrice(lpToken: ERC20, token: ERC20, isBased: boolean): Promise<string> {
    const totalSupply = getFullDisplayBalance(await lpToken.totalSupply(), lpToken.decimal);
    //Get amount of tokenA
    const tokenSupply = getFullDisplayBalance(await token.balanceOf(lpToken.address), token.decimal);
    const stat = isBased === true ? await this.getBasedStat() : await this.getShareStat();
    const priceOfToken = stat.priceInDollars;
    const tokenInLP = Number(tokenSupply) / Number(totalSupply);
    const tokenPrice = (Number(priceOfToken) * tokenInLP * 2) //We multiply by 2 since half the price of the lp token is the price of each piece of the pair. So twice gives the total
      .toString();
    return tokenPrice;
  }

  async earnedFromBank(
    poolName: ContractName,
    earnTokenName: String,
    poolId: Number,
    account = this.myAccount,
  ): Promise<BigNumber> {
    const pool = this.contracts[poolName];
    try {
      return await pool.pendingReward(poolId, account);
    } catch (err) {
      console.error(`Failed to call earned() on pool ${pool.address}: ${err.stack}`);
      return BigNumber.from(0);
    }
  }

  async stakedBalanceOnBank(poolName: ContractName, poolId: Number, account = this.myAccount): Promise<BigNumber> {
    const pool = this.contracts[poolName];
    try {
      let userInfo = await pool.userInfo(poolId, account);
      return await userInfo.amount;
    } catch (err) {
      console.error(`Failed to call balanceOf() on pool ${pool.address}: ${err.stack}`);
      return BigNumber.from(0);
    }
  }

  /**
   * Deposits token to given pool.
   * @param poolName A name of pool contract.
   * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
   * @returns {string} Transaction hash
   */
  async stake(poolName: ContractName, poolId: Number, amount: BigNumber): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    return await pool.deposit(poolId, amount);
  }

  /**
   * Withdraws token from given pool.
   * @param poolName A name of pool contract.
   * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
   * @returns {string} Transaction hash
   */
  async unstake(poolName: ContractName, poolId: Number, amount: BigNumber): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    return await pool.withdraw(poolId, amount);
  }

  /**
   * Transfers earned token reward from given pool to my account.
   */
  async harvest(poolName: ContractName, poolId: Number): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    //By passing 0 as the amount, we are asking the contract to only redeem the reward and not the currently staked token
    return await pool.withdraw(poolId, 0);
  }

  /**
   * Harvests and withdraws deposited tokens from the pool.
   */
  async exit(poolName: ContractName, poolId: Number, account = this.myAccount): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    let userInfo = await pool.userInfo(poolId, account);
    return await pool.withdraw(poolId, userInfo.amount);
  }

  async fetchAcropolisVersionOfUser(): Promise<string> {
    return 'latest';
  }

  async fetchParthenonVersionOfUser(): Promise<string> {
    return 'latest';
  }

  currentAcropolis(): Contract {
    if (!this.acropolisVersionOfUser) {
      //throw new Error('you must unlock the wallet to continue.');
    }
    return this.contracts.Acropolis;
  }

  isOldAcropolisMember(): boolean {
    return this.acropolisVersionOfUser !== 'latest';
  }

  async computePairAddress(factoryAddress:string,
                           tokenAAddress:string,
                           tokenBAddress:string): Promise<string>
  {
    const [token0, token1] = tokenAAddress.toLowerCase() < tokenBAddress.toLowerCase()
        ? [tokenAAddress, tokenBAddress] : [tokenBAddress, tokenAAddress]
    return getCreate2Address(
        factoryAddress,
        keccak256(['bytes'], [pack(['address', 'address'], [token0, token1])]),
        "0x9bbbc3a1671835a95223cff5fac60a303310de646cf33d8a420bcb4474601f33"
    )
  }


  async getTokenPriceInTomb(tokenContract: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { chainId } = this.config;
    const { TOMB } = this.config.externalTokens;

    const tomb = new Stoken(chainId, TOMB[0], TOMB[1]);
    const token = new Stoken(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
    let pairAddresss = this.computePairAddress("0x407C47E3FDB7952Ee53aa232B5f28566A024A759",
        tomb.address,token.address);
    let lp = new Contract(await pairAddresss, IUniswapV2PairABI, this.provider);

    let [reserveA,reserveB] = await lp.getReserves();
    const [reserveAA, reserveBB] = tomb.address.toLowerCase() < token.address.toLowerCase()
        ? [reserveA, reserveB] : [reserveB, reserveA];
    const pair = new Pair(CurrencyAmount.fromRawAmount(tomb, reserveAA.toString()),
        CurrencyAmount.fromRawAmount(token, reserveBB.toString()));

    try {
    //  const tombToToken = await Fetcher.fetchPairData(tomb, token, this.provider);
      const priceInBUSD = new Sroute([pair], token,tomb);

      return priceInBUSD.midPrice.toFixed(4);
    } catch (err) {
      console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
    }
  }

  async getTokenPriceInFtmShort(tokenContract: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { chainId } = this.config;
    const { WFTM } = this.config.externalTokens;

    const wftm = new Stoken(chainId, WFTM[0], WFTM[1]);
    const token = new Stoken(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
    let pairAddresss = this.computePairAddress("0x407C47E3FDB7952Ee53aa232B5f28566A024A759",
        wftm.address,token.address);
    let lp = new Contract(await pairAddresss, IUniswapV2PairABI, this.provider);

    let [reserveA,reserveB] = await lp.getReserves();
    const [reserveAA, reserveBB] = wftm.address.toLowerCase() < token.address.toLowerCase()
        ? [reserveA, reserveB] : [reserveB, reserveA];
    const pair = new Pair(CurrencyAmount.fromRawAmount(wftm, reserveAA.toString()),
        CurrencyAmount.fromRawAmount(token, reserveBB.toString()));

    try {

      //  const tombToToken = await Fetcher.fetchPairData(tomb, token, this.provider);
      const priceInBUSD = new Sroute([pair], token,wftm);

      return priceInBUSD.midPrice.toFixed(4);
    } catch (err) {
      console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
    }

  }

  async getTokenPriceInFtm(tokenContract: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { chainId } = this.config;

    const { TOMB } = this.externalTokens;

    const tomb = new TokenSpirit(chainId, TOMB.address, TOMB.decimal);
    const token = new TokenSpirit(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
    try {
      const tombToToken = await FetcherSpirit.fetchPairData(tomb, token, this.provider);
      const liquidityToken = tombToToken.liquidityToken;
      let tombBalanceInLP = await TOMB.balanceOf(liquidityToken.address);
      let tombAmount = Number(getFullDisplayBalance(tombBalanceInLP, TOMB.decimal));
      let shibaBalanceInLP = await tokenContract.balanceOf(liquidityToken.address);
      let shibaAmount = Number(getFullDisplayBalance(shibaBalanceInLP, tokenContract.decimal));
      const priceOfOneTombInFtm = await this.getOneTOMBPriceInFTM();
      let priceOfShiba = (tombAmount / shibaAmount) * Number(priceOfOneTombInFtm);
      return priceOfShiba.toString();
    } catch (err) {
      console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
    }
  }

  async getOneTOMBPriceInFTM(): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { WFTM, TOMB } = this.externalTokens;
    try {
      const ftm_tomb_lp_pair = this.externalTokens['FTM-TOMB-LP'];
      let tomb_amount_BN = await TOMB.balanceOf(ftm_tomb_lp_pair.address);
      let tomb_amount = Number(getFullDisplayBalance(tomb_amount_BN, TOMB.decimal));
      let ftm_amount_BN = await WFTM.balanceOf(ftm_tomb_lp_pair.address);
      let ftm_amount = Number(getFullDisplayBalance(ftm_amount_BN, WFTM.decimal));
      return (ftm_amount / tomb_amount).toString();
    } catch (err) {
      console.error(`Failed to fetch token price of TOMB: ${err}`);
    }
  }

  async getFTMPriceInDollars(): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { WFTM, USDC } = this.externalTokens;
    try {
      const usdc_ftm_lp_pair = this.externalTokens['FTM-USDC-LP'];
      let ftm_amount_BN = await WFTM.balanceOf(usdc_ftm_lp_pair.address);
      let ftm_amount = Number(getFullDisplayBalance(ftm_amount_BN, WFTM.decimal));
      let usdc_amount_BN = await USDC.balanceOf(usdc_ftm_lp_pair.address);
      let usdc_amount = Number(getFullDisplayBalance(usdc_amount_BN, USDC.decimal));
      return (usdc_amount / ftm_amount).toString();
    } catch (err) {
      console.error(`Failed to fetch token price of WFTM: ${err}`);
    }
  }

  async getTricryptoPriceInDollars(): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { VyperContractOracle, VyperContactToken } = this.contracts;

    try {
      // get ETH supply
      const supply_eth_BN = await VyperContractOracle.balances(2);
      let supply_eth = Number(getFullDisplayBalance(supply_eth_BN, 18));

      // get Pool total supply
      let total_pool_supply_BN = await VyperContactToken.totalSupply();
      let total_pool_supply = Number(getFullDisplayBalance(total_pool_supply_BN, 18));

      // get ETH price in USD
      const price_eth_BN = await VyperContractOracle.price_oracle(1);
      let price_eth = Number(getFullDisplayBalance(price_eth_BN, 18));

      // get ETH total value
      let total_eth_value = supply_eth * price_eth;

      // get Pool total value
      let total_pool_value = total_eth_value * 3;

      // calc Tricrypto USD price
      let tricrypto_price_usd = (total_pool_value / total_pool_supply).toFixed(2);

      return tricrypto_price_usd.toString();
    } catch (err) {
      console.error(`Failed to fetch token price of Tricrypto: ${err}`);
    }
  }

  //===================================================================
  //===================================================================
  //===================== ACROPOLIS METHODS =============================
  //===================================================================
  //===================================================================

  async getAcropolisAPR() {
    const Acropolis = this.currentAcropolis();
    const latestSnapshotIndex = await Acropolis.latestSnapshotIndex();
    const lastHistory = await Acropolis.acropolisHistory(latestSnapshotIndex);

    const lastRewardsReceived = lastHistory[1];

    const BSHAREPrice = (await this.getShareStat()).priceInDollars;
    const BASEDPrice = (await this.getBasedStat()).priceInDollars;
    const epochRewardsPerShare = lastRewardsReceived / 1e18;

    //Mgod formula
    const amountOfRewardsPerDay = epochRewardsPerShare * Number(BASEDPrice) * 4;
    const acropolisbShareBalanceOf = await this.BSHARE.balanceOf(Acropolis.address);
    const acropolisTVL = Number(getDisplayBalance(acropolisbShareBalanceOf, this.BSHARE.decimal)) * Number(BSHAREPrice);
    const realAPR = ((amountOfRewardsPerDay * 100) / acropolisTVL) * 365;
    return realAPR;
  }

  async getBasedPerBshare() {
    const Acropolis = this.currentAcropolis();
    const latestSnapshotIndex = await Acropolis.latestSnapshotIndex();
    const lastHistory = await Acropolis.acropolisHistory(latestSnapshotIndex);

    const lastRewardsReceived = lastHistory[1];

    const epochRewardsPerShare = lastRewardsReceived / 1e18;
    const acropolisbShareBalanceOf = await this.BSHARE.balanceOf(Acropolis.address);

    return epochRewardsPerShare / Number(getDisplayBalance(acropolisbShareBalanceOf, this.BSHARE.decimal));
  }

  /**
   * Checks if the user is allowed to retrieve their reward from the Acropolis
   * @returns true if user can withdraw reward, false if they can't
   */
  async canUserClaimRewardFromAcropolis(): Promise<boolean> {
    const Acropolis = this.currentAcropolis();
    return await Acropolis.canClaimReward(this.myAccount);
  }

  /**
   * Checks if the user is allowed to retrieve their reward from the Acropolis
   * @returns true if user can withdraw reward, false if they can't
   */
  async canUserUnstakeFromAcropolis(): Promise<boolean> {
    const Acropolis = this.currentAcropolis();
    const canWithdraw = await Acropolis.canWithdraw(this.myAccount);
    const stakedAmount = await this.getStakedSharesOnAcropolis();
    const notStaked = Number(getDisplayBalance(stakedAmount, this.BSHARE.decimal)) === 0;
    const result = notStaked ? true : canWithdraw;
    return result;
  }

  async timeUntilClaimRewardFromAcropolis(): Promise<BigNumber> {
    // const Acropolis = this.currentAcropolis();
    // const mason = await Acropolis.masons(this.myAccount);
    return BigNumber.from(0);
  }

  async getTotalStakedInAcropolis(): Promise<BigNumber> {
    const Acropolis = this.currentAcropolis();
    return await Acropolis.totalSupply();
  }

  async stakeShareToAcropolis(amount: string): Promise<TransactionResponse> {
    if (this.isOldAcropolisMember()) {
      throw new Error("you're using old acropolis. please withdraw and deposit the BSHARE again.");
    }
    const Acropolis = this.currentAcropolis();
    return await Acropolis.stake(decimalToBalance(amount));
  }

  async getStakedSharesOnAcropolis(): Promise<BigNumber> {
    const Acropolis = this.currentAcropolis();
    if (this.acropolisVersionOfUser === 'v1') {
      return await Acropolis.getShareOf(this.myAccount);
    }
    return await Acropolis.balanceOf(this.myAccount);
  }

  async getEarningsOnAcropolis(): Promise<BigNumber> {
    const Acropolis = this.currentAcropolis();
    if (this.acropolisVersionOfUser === 'v1') {
      return await Acropolis.getCashEarningsOf(this.myAccount);
    }
    return await Acropolis.earned(this.myAccount);
  }

  async withdrawShareFromAcropolis(amount: string): Promise<TransactionResponse> {
    const Acropolis = this.currentAcropolis();
    return await Acropolis.withdraw(decimalToBalance(amount));
  }

  async harvestCashFromAcropolis(): Promise<TransactionResponse> {
    const Acropolis = this.currentAcropolis();
    if (this.acropolisVersionOfUser === 'v1') {
      return await Acropolis.claimDividends();
    }
    return await Acropolis.claimReward();
  }

  async exitFromAcropolis(): Promise<TransactionResponse> {
    const Acropolis = this.currentAcropolis();
    return await Acropolis.exit();
  }

  async getTreasuryNextAllocationTime(): Promise<AllocationTime> {
    const { Treasury } = this.contracts;
    const nextEpochTimestamp: BigNumber = await Treasury.nextEpochPoint();
    const nextAllocation = new Date(nextEpochTimestamp.mul(1000).toNumber());
    const prevAllocation = new Date(Date.now());

    return { from: prevAllocation, to: nextAllocation };
  }

  async uiAllocate(): Promise<TransactionResponse> {
    const { Treasury } = this.contracts;
    return await Treasury.allocateSeigniorage();
  }

  /**
   * This method calculates and returns in a from to to format
   * the period the user needs to wait before being allowed to claim
   * their reward from the acropolis
   * @returns Promise<AllocationTime>
   */
  async getUserClaimRewardTime(): Promise<AllocationTime> {
    const { Acropolis, Treasury } = this.contracts;
    const nextEpochTimestamp = await Acropolis.nextEpochPoint(); //in unix timestamp
    const currentEpoch = await Acropolis.epoch();
    const Ecclesiaseat = await Acropolis.demos(this.myAccount);
    const startTimeEpoch = Ecclesiaseat.epochTimerStart;
    const period = await Treasury.PERIOD();
    const periodInHours = period / 60 / 60; // 6 hours, period is displayed in seconds which is 21600
    const rewardLockupEpochs = await Acropolis.rewardLockupEpochs();
    const targetEpochForClaimUnlock = Number(startTimeEpoch) + Number(rewardLockupEpochs);

    const fromDate = new Date(Date.now());
    if (targetEpochForClaimUnlock - currentEpoch <= 0) {
      return { from: fromDate, to: fromDate };
    } else if (targetEpochForClaimUnlock - currentEpoch === 1) {
      const toDate = new Date(nextEpochTimestamp * 1000);
      return { from: fromDate, to: toDate };
    } else {
      const toDate = new Date(nextEpochTimestamp * 1000);
      const delta = targetEpochForClaimUnlock - currentEpoch - 1;
      const endDate = moment(toDate)
        .add(delta * periodInHours, 'hours')
        .toDate();
      return { from: fromDate, to: endDate };
    }
  }

  /**
   * This method calculates and returns in a from to to format
   * the period the user needs to wait before being allowed to unstake
   * from the acropolis
   * @returns Promise<AllocationTime>
   */
  async getUserUnstakeTime(): Promise<AllocationTime> {
    const { Acropolis, Treasury } = this.contracts;
    const nextEpochTimestamp = await Acropolis.nextEpochPoint();
    const currentEpoch = await Acropolis.epoch();
    const Ecclesiaseat = await Acropolis.demos(this.myAccount);
    const startTimeEpoch = Ecclesiaseat.epochTimerStart;
    const period = await Treasury.PERIOD();
    const PeriodInHours = period / 60 / 60;
    const withdrawLockupEpochs = await Acropolis.withdrawLockupEpochs();
    const fromDate = new Date(Date.now());
    const targetEpochForClaimUnlock = Number(startTimeEpoch) + Number(withdrawLockupEpochs);
    const stakedAmount = await this.getStakedSharesOnAcropolis();
    if (currentEpoch <= targetEpochForClaimUnlock && Number(stakedAmount) === 0) {
      return { from: fromDate, to: fromDate };
    } else if (targetEpochForClaimUnlock - currentEpoch === 1) {
      const toDate = new Date(nextEpochTimestamp * 1000);
      return { from: fromDate, to: toDate };
    } else {
      const toDate = new Date(nextEpochTimestamp * 1000);
      const delta = targetEpochForClaimUnlock - Number(currentEpoch) - 1;
      const endDate = moment(toDate)
        .add(delta * PeriodInHours, 'hours')
        .toDate();
      return { from: fromDate, to: endDate };
    }
  }

  //TODO add proper icons
  async watchAssetInMetamask(assetName: string): Promise<boolean> {
    const { ethereum } = window as any;
    if (ethereum && ethereum.networkVersion === config.chainId.toString()) {
      let asset;
      let assetUrl;
      if (assetName === 'BASED') {
        asset = this.BASED;
        assetUrl = window.location.origin + '/based.svg';
      } else if (assetName === 'BSHARE') {
        asset = this.BSHARE;
        assetUrl = window.location.origin + '/bshare.svg';
      } else if (assetName === 'TOMB') {
        asset = this.TOMB;
        assetUrl = window.location.origin + '/tomb.svg';
      } else if (assetName === 'SOLID') {
        asset = this.SOLID;
        assetUrl = window.location.origin + '/solidly.png';
      } else if (assetName === 'TSHARE') {
        asset = this.TSHARE;
        assetUrl = window.location.origin + '/tshare.svg';
      } else if (assetName === 'BBOND') {
        asset = this.BBOND;
        assetUrl = window.location.origin + '/bbond.svg';
      }
      await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: asset.address,
            symbol: asset.symbol,
            decimals: 18,
            image: assetUrl,
          },
        },
      });
    }
    return true;
  }

  async provideBasedTombLP(tombAmount: string, basedAmount: BigNumber): Promise<TransactionResponse> {
    const { TaxOffice } = this.contracts;
    let overrides = {
      value: parseUnits(tombAmount, 18),
    };
    return await TaxOffice.addLiquidityETHTaxFree(
      basedAmount,
      basedAmount.mul(992).div(1000),
      parseUnits(tombAmount, 18).mul(992).div(1000),
      overrides,
    );
  }

  async quoteFromSpooky(tokenAmount: string, tokenName: string): Promise<string> {
    const { SpookyRouter } = this.contracts;
    const { _reserve0, _reserve1 } = await this.BASEDTOMB_LP.getReserves();
    let quote;
    if (tokenName === 'BASED') {
      quote = await SpookyRouter.quote(parseUnits(tokenAmount), _reserve1, _reserve0);
    } else {
      quote = await SpookyRouter.quote(parseUnits(tokenAmount), _reserve0, _reserve1);
    }
    return (quote / 1e18).toString();
  }

  getEventsLength(): number {
    return this.acropolisFundEvents.length;
  }

  /**
   * @returns an array of the regulation events till the most up to date epoch
   */
  async listenForRegulationsEvents(page: number, rowsPerPage: number): Promise<any> {
    const { Treasury } = this.contracts;
    const treasuryDevFundedFilter = Treasury.filters.DevFundFunded();
    const treasuryDaoFundedFilter = Treasury.filters.DaoFundFunded();
    const treasuryTeamFundedFilter = Treasury.filters.TeamFundFunded();
    const boughtBondsFilter = Treasury.filters.BoughtBonds();
    const redeemBondsFilter = Treasury.filters.RedeemedBonds();

    var events: any[] = [];

    let perPage = rowsPerPage;
    //At this moment we download all possible Acropolis fund events. For pagination we should slice array by index
    //startPageAddress is a start index of event's array.
    let startPageAddress = rowsPerPage;
    let endPageAddress = page * perPage;

    if (page > 0) startPageAddress = perPage * (page + 1);

    //Start block is always one 30460397 in acropolisFundEvents
    let firstEpochBlockPerHistory = 30460397;
    let eva: Array<Event>;
    eva = [];

    let currentEpoch = Number(await this.getCurrentEpoch());
    let epochBlocksRanges: any[] = [];

    if (this.acropolisFundEvents.length == 0 || currentEpoch != this.lastEpoch) {
      const treasuryAcropolisFundedFilter = Treasury.filters.AcropolisFunded();
      this.acropolisFundEvents = await Treasury.queryFilter(
        treasuryAcropolisFundedFilter,
        firstEpochBlockPerHistory,
        'latest',
      );
      this.lastEpoch = currentEpoch;
    }

    //Something went wrong
    if (this.acropolisFundEvents.length === 0) {
      console.error('Acropolis fund events length = 0');
      return events;
    }

    if (this.acropolisFundEvents.length < startPageAddress) {
      startPageAddress = this.acropolisFundEvents.length;
    }

    eva = this.acropolisFundEvents.slice(
      this.acropolisFundEvents.length - startPageAddress,
      this.acropolisFundEvents.length - endPageAddress,
    );

    let firstEpochTime = this.acropolisFundEvents[0].args.timestamp;
    let firstBlockNum = eva[0].blockNumber;

    let lastBlockNum = 0;

    eva.forEach(function callback(value, index) {
      let currentTimeStamp = value.args.timestamp;
      let basedEpoch = 21600;

      events.push({ epoch: Math.round((currentTimeStamp - firstEpochTime) / basedEpoch) + 1 });
      // }
      events[index].acropolisFund = getDisplayBalance(value.args[1]);

      if (index === 0) {
        epochBlocksRanges.push({
          index: index,
          startBlock: value.blockNumber,
          boughBonds: 0,
          redeemedBonds: 0,
        });
      }
      if (index > 0) {
        epochBlocksRanges.push({
          index: index,
          startBlock: value.blockNumber,
          boughBonds: 0,
          redeemedBonds: 0,
        });

        epochBlocksRanges[index - 1].endBlock = value.blockNumber;
        lastBlockNum = value.blockNumber;
      }
    });

    //Usually for the last epoch we set undefined but for other pages where the epoch is not the last we have to set the existing block number
    if (page > 0) {
      epochBlocksRanges[epochBlocksRanges.length - 1].endBlock =
        this.acropolisFundEvents[this.acropolisFundEvents.length - startPageAddress].blockNumber;
    }

    await Promise.all(
      epochBlocksRanges.map(async (value, index) => {
        events[index].bondsBought = await this.getBondsWithFilterForPeriod(
          boughtBondsFilter,
          value.startBlock,
          value.endBlock,
        );

        events[index].bondsRedeemed = await this.getBondsWithFilterForPeriod(
          redeemBondsFilter,
          value.startBlock,
          value.endBlock,
        );
      }),
    );

    let DEVFundEvents = await Treasury.queryFilter(treasuryDevFundedFilter, firstBlockNum, lastBlockNum);
    DEVFundEvents.forEach(function callback(value, index) {
      events[index].devFund = getDisplayBalance(value.args[1]);
    });

    let DAOFundEvents = await Treasury.queryFilter(treasuryDaoFundedFilter, firstBlockNum, lastBlockNum);
    DAOFundEvents.forEach(function callback(value, index) {
      events[index].daoFund = getDisplayBalance(value.args[1]);
    });

    let TeamFundEvents = await Treasury.queryFilter(treasuryTeamFundedFilter, firstBlockNum, lastBlockNum);
    TeamFundEvents.forEach(function callback(value, index) {
      events[index].teamFund = getDisplayBalance(value.args[1]);
    });

    return events;
  }

  /**
   * Helper method
   * @param filter applied on the query to the treasury events
   * @param from block number
   * @param to block number
   * @returns the amount of bonds events emitted based on the filter provided during a specific period
   */
  async getBondsWithFilterForPeriod(filter: EventFilter, from: number, to: number): Promise<number> {
    let totalBondAmount = 0;
    const { Treasury } = this.contracts;
    try {
      const bondsAmount = await Treasury.queryFilter(filter, from, to);
      if (bondsAmount.length === 0) return 0;
      bondsAmount.forEach((element) => {
        let bondAmount = element.args.bondAmount;
        if (bondAmount) {
          totalBondAmount += Number(bondAmount) / 1e18;
        }
      });
    } catch (e) {
      console.log(e);
    }

    return totalBondAmount;
  }

  /**
   * Zap-in Helper method
   * @param tokenName token for LP
   * @param lpName LP that is being created
   * @param amount amount of token for LP estimation
   */
  async estimateBasedTombZapIn(tokenName: string, lpName: string, amount: string): Promise<number[]> {
    const { BasedTombZap } = this.contracts;
    const lpToken = this.externalTokens[lpName];
    let estimate;
    let token: ERC20;
    let router;

    switch (tokenName) {
      case TOMB_TICKER:
        token = this.TOMB;
        break;
      case MAI_TICKER:
        token = this.MAI;
        break;
      case BASED_TICKER:
        token = this.BASED;
        break;
      default:
        token = this.BSHARE;
    }
    if (lpToken.address === this.BTLP.address) {
      router = SPOOKY_ROUTER_ADDR;
    } else {
      router = TOMBSWAP_ROUTER_ADDR;
    }
    estimate = await BasedTombZap.estimateZapInToken(token.address, lpToken.address, router, parseUnits(amount, 18));
    return [estimate[0] / 1e18, estimate[1] / 1e18];
  }

  /**
   * Zap-in Helper method
   * @param lpName lp to zap out
   * @param tokenName token to zap out to
   * @param minTokenAmount0 amount of token1 for LP estimation
   * @param minTokenAmount1 amount of token2 for LP estimation
   */
  async estimateBasedTombZapOut(
    lpName: string,
    tokenName: string,
    minTokenAmount0: string,
    minTokenAmount1: string,
  ): Promise<number> {
    const { BasedTombZap } = this.contracts;
    const lpToken = this.externalTokens[lpName];
    let estimate;
    let token: ERC20;
    let router;

    switch (tokenName) {
      case TOMB_TICKER:
        token = this.TOMB;
        break;
      case BASED_TICKER:
        token = this.BASED;
        break;
      case MAI_TICKER:
        token = this.MAI;
        break;
      default:
        token = this.BSHARE;
    }

    if (lpToken.address === this.BTLP.address) {
      router = SPOOKY_ROUTER_ADDR;
    } else {
      router = TOMBSWAP_ROUTER_ADDR;
    }

    estimate = await BasedTombZap.estimateZapOutToken(
      lpToken.address,
      token.address,
      router,
      parseUnits(minTokenAmount0, 18),
      parseUnits(minTokenAmount1, 18),
    );
    return estimate / 1e18;
  }

  /**
   * Zap-in Helper method
   * @param lpName lp to zap out
   * @param tokenName token to zap out to
   * @param minTokenAmount0 amount of token1 for LP estimation
   * @param minTokenAmount1 amount of token2 for LP estimation
   */
  async estimateBshareFtmZapOut(
    lpName: string,
    tokenName: string,
    minTokenAmount0: string,
    minTokenAmount1: string,
  ): Promise<number> {
    const { BshareFtmZap } = this.contracts;
    const lpToken = this.externalTokens[lpName];
    let estimate;
    let token: ERC20;
    switch (tokenName) {
      case FTM_TICKER:
        token = this.FTM;
        break;
      case WFTM_TICKER:
        token = this.WFTM;
        break;
      case BASED_TICKER:
        token = this.BASED;
        break;
      default:
        token = this.BSHARE;
    }

    estimate = await BshareFtmZap.estimateZapOutToken(
      lpToken.address,
      token.address,
      SPOOKY_ROUTER_ADDR,
      parseUnits(minTokenAmount0, 18),
      parseUnits(minTokenAmount1, 18),
    );
    return estimate / 1e18;
  }

  /**
   * Zap-in Helper method
   * @param tokenName token for LP
   * @param lpName LP that is being created
   * @param amount amount of token for LP
   */
  async estimateBshareFtmZapIn(tokenName: string, lpName: string, amount: string): Promise<number[]> {
    const { BshareFtmZap } = this.contracts;
    const lpToken = this.externalTokens[lpName];
    let estimate;
    let token: ERC20;
    switch (tokenName) {
      case WFTM_TICKER:
        token = this.WFTM;
        break;
      case FTM_TICKER:
        token = this.FTM;
        break;
      case BASED_TICKER:
        token = this.BASED;
        break;
      default:
        token = this.BSHARE;
    }
    estimate = await BshareFtmZap.estimateZapInToken(
      token.address,
      lpToken.address,
      SPOOKY_ROUTER_ADDR,
      parseUnits(amount, 18),
    );
    return [estimate[0] / 1e18, estimate[1] / 1e18];
  }

  /**
   * Zap-in Helper method
   * @param tokenName token for LP
   * @param lpName LP that is being created
   * @param amount amount of token for LP
   * @param minTokenAmount min amount of token to receive (slippage)
   */
  async basedTombZapIn(
    tokenName: string,
    lpName: string,
    amount: string,
    minTokenAmount: string,
  ): Promise<TransactionResponse> {
    const { BasedTombZap } = this.contracts;
    const lpToken = this.externalTokens[lpName];
    let token: ERC20;
    let router;

    switch (tokenName) {
      case TOMB_TICKER:
        token = this.TOMB;
        break;
      case MAI_TICKER:
        token = this.MAI;
        break;
      case BASED_TICKER:
        token = this.BASED;
        break;
      default:
        token = this.BSHARE;
    }

    if (lpToken.address === this.BMLP.address || lpToken.address === this.BTTSLP.address) {
      router = TOMBSWAP_ROUTER_ADDR;
    } else {
      router = SPOOKY_ROUTER_ADDR;
    }

    if (tokenName === TOMB_TICKER) {
      return await BasedTombZap.nativeZapIn(
        parseUnits(amount, 18),
        lpToken.address,
        router,
        this.myAccount,
        parseUnits(minTokenAmount, 18),
      );
    }
    return await BasedTombZap.zapInToken(
      token.address,
      parseUnits(amount, 18),
      lpToken.address,
      router,
      this.myAccount,
      parseUnits(minTokenAmount, 18),
    );
  }

  async basedTombZapOut(
    lpName: string,
    amount: string,
    zapOutTokenName: string,
    minAmountToken: string,
  ): Promise<TransactionResponse> {
    const { BasedTombZap } = this.contracts;
    const lpToken = this.externalTokens[lpName];
    let zapOutToken: ERC20;
    let router;

    switch (zapOutTokenName) {
      case TOMB_TICKER:
        zapOutToken = this.TOMB;
        break;
      case BASED_TICKER:
        zapOutToken = this.BASED;
        break;
      case MAI_TICKER:
        zapOutToken = this.MAI;
        break;
      default:
        zapOutToken = this.BSHARE;
    }

    if (lpToken.address === this.BTLP.address) {
      router = SPOOKY_ROUTER_ADDR;
    } else {
      router = TOMBSWAP_ROUTER_ADDR;
    }

    return await BasedTombZap.zapOutToToken(
      lpToken.address,
      parseUnits(amount, 18),
      zapOutToken.address,
      router,
      this.myAccount,
      parseUnits(minAmountToken, 18),
    );
  }

  async bshareFtmZapOut(
    lpName: string,
    amount: string,
    zapOutTokenName: string,
    minAmountToken: string,
  ): Promise<TransactionResponse> {
    const { BshareFtmZap } = this.contracts;
    const lpToken = this.externalTokens[lpName];
    let zapOutToken: ERC20;
    switch (zapOutTokenName) {
      case FTM_TICKER:
        zapOutToken = this.FTM;
        break;
      case WFTM_TICKER:
        zapOutToken = this.WFTM;
        break;
      case BASED_TICKER:
        zapOutToken = this.BASED;
        break;
      default:
        zapOutToken = this.BSHARE;
    }

    if (zapOutToken === this.FTM) {
      return await BshareFtmZap.zapOutToNative(
        lpToken.address,
        parseUnits(amount, 18),
        SPOOKY_ROUTER_ADDR,
        this.myAccount,
        parseUnits(minAmountToken, 18),
      );
    } else {
      return await BshareFtmZap.zapOutToToken(
        lpToken.address,
        parseUnits(amount, 18),
        zapOutToken.address,
        SPOOKY_ROUTER_ADDR,
        this.myAccount,
        parseUnits(minAmountToken, 18),
      );
    }
  }

  async bshareFtmZapIn(
    tokenName: string,
    lpName: string,
    amount: string,
    minTokenAmount: string,
  ): Promise<TransactionResponse> {
    const { BshareFtmZap } = this.contracts;
    const lpToken = this.externalTokens[lpName];
    let token: ERC20;
    switch (tokenName) {
      case WFTM_TICKER:
        token = this.WFTM;
        break;
      case FTM_TICKER:
        token = this.FTM;
        break;
      case BASED_TICKER:
        token = this.BASED;
        break;
      default:
        token = this.BSHARE;
    }
    if (tokenName === FTM_TICKER) {
      return await BshareFtmZap.nativeZapIn(
        parseUnits(amount, 18),
        lpToken.address,
        SPOOKY_ROUTER_ADDR,
        this.myAccount,
        parseUnits(minTokenAmount, 18),
      );
    } else {
      return await BshareFtmZap.zapInToken(
        token.address,
        parseUnits(amount, 18),
        lpToken.address,
        SPOOKY_ROUTER_ADDR,
        this.myAccount,
        parseUnits(minTokenAmount, 18),
      );
    }
  }

  async swapBBondToBShare(bbondAmount: BigNumber): Promise<TransactionResponse> {
    const { BShareSwapper } = this.contracts;
    return await BShareSwapper.swapBBondToBShare(bbondAmount);
  }

  async estimateAmountOfBShare(bbondAmount: string): Promise<string> {
    const { BShareSwapper } = this.contracts;
    try {
      const estimateBN = await BShareSwapper.estimateAmountOfBShare(parseUnits(bbondAmount, 18));
      return getDisplayBalance(estimateBN, 18, 6);
    } catch (err) {
      console.error(`Failed to fetch estimate bshare amount: ${err}`);
    }
  }

  async getBShareSwapperStat(address: string): Promise<BShareSwapperStat> {
    const { BShareSwapper } = this.contracts;
    const bshareBalanceBN = await BShareSwapper.getBShareBalance();
    const bbondBalanceBN = await BShareSwapper.getBBondBalance(address);
    // const basedPriceBN = await BShareSwapper.getBasedPrice();
    // const bsharePriceBN = await BShareSwapper.getBSharePrice();
    const rateBSharePerBasedBN = await BShareSwapper.getBShareAmountPerBased();
    const bshareBalance = getDisplayBalance(bshareBalanceBN, 18, 5);
    const bbondBalance = getDisplayBalance(bbondBalanceBN, 18, 5);
    return {
      bshareBalance: bshareBalance.toString(),
      bbondBalance: bbondBalance.toString(),
      // basedPrice: basedPriceBN.toString(),
      // bsharePrice: bsharePriceBN.toString(),
      rateBSharePerBased: rateBSharePerBasedBN.toString(),
    };
  }

  getTokenFromTicker(ticker: string): ERC20 {
    switch (ticker) {
      case TICKER.BSHARE:
        return this.BSHARE;
      case TICKER.USDC:
        return this.USDC;
      case TICKER.MAI:
        return this.MAI;
      case TICKER.TOMB:
        return this.TOMB;
      case TICKER.BSHARE:
        return this.BSHARE;
      case TICKER.FTM:
        return this.FTM;
      case TICKER.WFTM:
        return this.WFTM;
      case TICKER.BBOND:
        return this.BBOND;
      case TICKER.BASED:
      default:
        return this.BASED;
    }
  }

  getPoolIdFromPoolName(poolName: string): number {
    switch (poolName) {
      case 'BASED-TOMB-LP':
        return 0;
      case 'BSHARE-FTM-LP':
        return 1;
      // case 'STATER':
      //   return 2;
      // case 'CURVE_GEIST':
      //   return 3;
      // case 'CURVE_TRICRYPTO':
      //   return 4;
      // case 'BASED-BSHARE-LP':
      //   return 5;
      // case 'BASED-MAI-LP':
      //   return 6;
      // case 'BASED-TOMB-TSWAP-LP':
      //   return 7;
      // case 'BASED-USDC-LP':
      //   return 8;
      // case 'CURVE_GEIST_V2':
      //   return 9;
      // case 'CURVE_TRICRYPTO_V2':
      //   return 10;
      case 'BBOND':
        return 11;
    }
  }

  async getExternalTokenBalanceByName(tokenName: string, account: string): Promise<BigNumber> {
    let contract = this.externalTokens[tokenName];
    if (!contract && !account) return BigNumber.from(0);
    return await contract.balanceOf(account);
  }

  // added line breaks to make it a round number of 1k lines :))
}
