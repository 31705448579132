export const white = '#FFF';
export const black = '#000';

export const green = {
  500: '#00D110',
};

export const purple = {
  200: '#bd7cfe',
  400: '#ab47bc',
  700: '#7b1fa2',
};

export const red = {
  100: '#C1C1FF',
  200: '#7575FF',
  500: '#9090FF',
};

export const grey = {
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
};

export const bg = '#000000';

export const teal = {
  200: '#64ffda',
};

export const based = {
  200: '#757ce8',
  400: '#571eb1',
  700: '#2c2560',
};

export const cardBackground = 'rgba(248, 242, 242, 0.5)';

const colors = {
  white,
  black,
  green,
  purple,
  red,
  grey,
  bg,
  teal,
  based,
  cardBackground,
};
export default colors;
