import { BasedFinance } from './BasedFinance';
import { BigNumber, Contract, ethers } from 'ethers';
import { TransactionResponse } from '@ethersproject/providers';
import { getDisplayBalance, getFullDisplayBalance } from '../utils/formatBalance';
import { SPOOKY_ROUTER_ADDR, TOMBSWAP_ROUTER_ADDR, TICKER, BBOND_TICKER } from '../utils/constants';
import { Fetcher, Route, Token } from '@spookyswap/sdk';
import ERC20 from './ERC20';
import { parseUnits } from 'ethers/lib/utils';

export class Katastima {
  basedFinance: BasedFinance;

  constructor(basedFinance: BasedFinance) {
    this.basedFinance = basedFinance;
  }

  /**
   * Get balance of selected token in user's wallet
   * @param selectedTicker purchase token ticker
   */
  async getTokenFullBalance(selectedTicker: string): Promise<string> {
    let stat: string = '';
    const selectedToken = this.basedFinance.getTokenFromTicker(selectedTicker);

    const isUnlocked = this.basedFinance?.isUnlocked;

    if (selectedToken && isUnlocked) {
      let tokenBalance: BigNumber = BigNumber.from(0);

      //Need to think about it
      if (selectedTicker === TICKER.BASED) tokenBalance = await this.basedFinance.getBasedTresuryBalance();
      else tokenBalance = await selectedToken.balanceOf(this.basedFinance.myAccount);

      const displayBalance = getFullDisplayBalance(tokenBalance, selectedToken.decimal);
      return displayBalance;
    }
    return stat;
  }

  /**
   * Get OTC contract discount percentage
   * @selectedReceiveTicker asset user wants to receive
   */
  async getOtcDiscountValue(selectedReceiveTicker: string): Promise<Number> {
    const { OTCBASED, OTCBSHARE } = this.basedFinance.contracts;
    let otcDiscountValue;
    if (selectedReceiveTicker === 'BSHARE') {
      otcDiscountValue = await OTCBSHARE.CurrentDiscount();
    } else {
      otcDiscountValue = await OTCBASED.CurrentDiscount();
    }
    return otcDiscountValue;
  }

  /**
   * Get total amount of BASED in the OTC contract
   * @selectedReceiveTicker asset user wants to receive
   */
  async getOtcTokensAvailable(selectedReceiveTicker: string): Promise<Number> {
    const { OTCBBOND, OTCBSHARE } = this.basedFinance.contracts;
    let tokenAvailable;
    if (selectedReceiveTicker === 'BSHARE') {
      tokenAvailable = await this.basedFinance.BSHARE.balanceOf(OTCBSHARE.address);
    } else {
      tokenAvailable = await this.basedFinance.BASED.balanceOf(OTCBBOND.address);
    }
    return Number(getDisplayBalance(tokenAvailable, 18));
  }

  /**
   * Calculate total money saved compared to pure market swap
   * @selectedReceiveTicker asset user wants to receive
   */
  async getTotalMoneySaved(selectedReceiveTicker: string): Promise<Number> {
    const { OTCBBOND, OTCBSHARE } = this.basedFinance.contracts;
    let discount: number = 0.0025;
    let totalTokensSwapped: number;
    //let unitPrice = Number(await this.getBasedPriceInToken('USDC'));
    if (selectedReceiveTicker === 'BSHARE') {
      totalTokensSwapped = Number(getDisplayBalance(await OTCBSHARE.TotalBasedSwapped(), 18));
    } else {
      totalTokensSwapped = Number(getDisplayBalance(await OTCBBOND.TotalBasedSwapped(), 18));
    }
    return totalTokensSwapped;// * discount;
  }

  /**
   * Get based percentage rate swapped on market
   * @return number
   */
  async getBasedMarketSwapPercentage(): Promise<Number> {
    const { OTCBASED } = this.basedFinance.contracts;
    let marketSwapPercentage = Number(await OTCBASED.MarketSwapPercentage());
    return marketSwapPercentage / 1000;
  }

  /**
   * Get bshare percentage rate swapped on market
   * @return number
   */
  async getBshareMarketSwapPercentage(): Promise<Number> {
    const { OTCBSHARE } = this.basedFinance.contracts;
    let marketSwapPercentage = Number(await OTCBSHARE.MarketSwapPercentage());
    return marketSwapPercentage / 1000;
  }

  /**
   * Calculate estimated swapped amount
   * @param amount token amount to estimate
   * @param uintPrice price of one token in BASED
   */
  async calculateEstimations(amount: number, uintPrice: number): Promise<number> {
    return uintPrice > 0 ? amount * uintPrice : 0;
  }

  /**
   * Calculate the price of 1 token in $
   * @param assetReceived asset user wants to receive
   * @param assetSwapped asset user wants to buy assetReceived with
   * @return priceInUSD: number
   */
  async getUnitPriceInToken(assetReceived: string, assetSwapped: string): Promise<number> {
    const { OTCBASED, OTCBSHARE, OTCBBOND } = this.basedFinance.contracts;
    // const tokenContract = this.basedFinance.getTokenFromTicker(assetSwapped);
     const tokenAmount = assetSwapped === 'USDC' ? parseUnits('1', 6) : parseUnits('1', 18);
     
    // const basedMarketSwapPercentage = await this.getBasedMarketSwapPercentage();
    // const basedOtcSwapPercentage = 1 - basedMarketSwapPercentage.valueOf();

    // const bshareMarketSwapPercentage = await this.getBshareMarketSwapPercentage();
    // const bshareOtcSwapPercentage = 1 - bshareMarketSwapPercentage.valueOf();

    if (!assetSwapped && !assetReceived) {
      console.error('Selected ticker is iqnvalid!');
      return undefined;
    }

    let assetPerTokenMarket;
    let assetPerTokenOtc;
    let medianAssetAmount;
    switch (assetReceived) {
      // case 'BSHARE':
      //   assetPerTokenMarket = Number(
      //     getDisplayBalance(
      //       await OTCBSHARE._estimateMarketSwap(tokenContract.address, tokenAmount, SPOOKY_ROUTER_ADDR),
      //       18,
      //       6,
      //     ),
      //   );
      //   assetPerTokenOtc = Number(
      //     getDisplayBalance(
      //       await OTCBSHARE._estimateOTCSwap(tokenContract.address, tokenAmount, SPOOKY_ROUTER_ADDR),
      //       18,
      //       6,
      //     ),
      //   );
      //   medianAssetAmount =
      //     assetPerTokenMarket * bshareMarketSwapPercentage.valueOf() + assetPerTokenOtc * bshareOtcSwapPercentage;
      //   break;
      default:
        // assetPerTokenMarket = Number(
        //   getDisplayBalance(
        //     await OTCBASED._estimateMarketSwap(tokenContract.address, tokenAmount, TOMBSWAP_ROUTER_ADDR),
        //     18,
        //     6,
        //   ),
        // );
        assetPerTokenOtc = Number(
          getDisplayBalance(
            await OTCBBOND._estimateOTCSwap( tokenAmount),
            18,
            6,
          ),
        );

        // medianAssetAmount =
        //   assetPerTokenMarket * basedMarketSwapPercentage.valueOf() + assetPerTokenOtc * basedOtcSwapPercentage;
    }

    // const priceInUSD = Number(1 / assetPerTokenOtc);
    return assetPerTokenOtc;
  }

  /**
   * OTC swap tokens
   * @param amount token amount to swap
   * @param assetReceivedTicker asset user wants to receive
   * @param assetSwappedTicker asset user wants to buy assetReceived with
   * @param estimateTokenAmount token price estimation from swapTokens
   */
  async instaSwapBond(
    amount: string,
    assetReceivedTicker: string,
    assetSwappedTicker: string,
    estimateTokenAmount: string,
  ): Promise<TransactionResponse> {
    const { OTCBBOND, OTCBSHARE } = this.basedFinance.contracts;
    let swapToken: ERC20 = this.basedFinance.getTokenFromTicker(assetSwappedTicker);
    let swapAmmount = assetSwappedTicker === 'USDC' ? parseUnits(amount, 6) : parseUnits(amount, 18);

    switch (assetReceivedTicker) {
      case 'BSHARE':
        return await OTCBSHARE.swapToken(
          swapToken.address,
          swapAmmount,
          SPOOKY_ROUTER_ADDR,
          parseUnits(estimateTokenAmount, 18),
        );
      default:
        return await OTCBBOND._swapOnOTC(
          swapToken.address,
          swapAmmount,
          parseUnits(estimateTokenAmount, 18),
        );
    }
  }

  /**
   * Swap native FTM to OTC token
   * @amount native FTM amount to swap
   */
  async instaSwapBondNative(amount: string): Promise<TransactionResponse> {
    return new Promise((resolve) => {
      //TODO add functionality when can proccess FTM correctly
    });
  }

  /**
   * OTC swap major function
   * @param assetReceivedTicker asset user wants to receive
   * @param assetSwappedTicker asset user wants to buy assetReceived with
   * @param amount token amount to swap
   */
  async swapTokens(
    assetReceivedTicker: string,
    assetSwappedTicker: string,
    amount: string,
  ): Promise<TransactionResponse> {
    const assetReceivedContract = this.basedFinance.getTokenFromTicker(assetReceivedTicker);
    const assetSwappedContract = this.basedFinance.getTokenFromTicker(assetSwappedTicker);
    if (!assetReceivedContract && !assetSwappedContract) {
      console.error('Selected ticker is invalid!');
      return undefined;
    }

    let freshTokenPrice = await this.getUnitPriceInToken(assetReceivedTicker, assetSwappedTicker);
    let estimation = await this.calculateEstimations(Number(amount), freshTokenPrice);
    estimation -= estimation * 0.05;

    let tx;
    if (assetSwappedTicker === TICKER.FTM) {
      tx = await this.instaSwapBondNative(amount);
    } else {
      tx = await this.instaSwapBond(amount, assetReceivedTicker, assetSwappedTicker, Number(estimation).toString());
    }

    return tx;
  }
}
